import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/config/APICongig";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  //   const [userData] = useState({});

  const [userData, setUserDate] = useState();
  const [autoTrade, setAutoTrade] = useState();
  const [added_exchange, setAdded_exchange] = useState([]);
  // const [popup, setPopup] = useState(false);
  const [exchangeNameList, setExchangeNameList] = useState([]);
  const [allExchangeTotal, setallExchangeTotal] = useState("");
  const [exchangeList, setExchangeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // const [notificationList, setNotificationList] = useState([]);
  const [listPartner, setlistPartner] = useState([]);
  const [listSocials, setlistSocials] = useState([]);
  // const [unreadCount, setUnreadCount] = useState(0);
  // console.log(notificationList?.length, "notificationList", notificationList);
  const getExchangeBalanceHandler = async () => {
    await axios({
      method: "POST",
      url: ApiConfig.getExchangeBalance,
      headers: {
        token: window.localStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          setExchangeNameList(res.data.result.responseResult);
          setallExchangeTotal(res.data.result.allExchangeTotal);
          setAdded_exchange(res.data.result.responseResult);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const viewProfileHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.viewProfile, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        setUserDate(res?.data?.result);
        setAutoTrade(res?.data?.result.autoTrade);
      } else {
        setUserDate();
        setIsLogin(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  const HandlelistPartner = async () => {
    //eslint-disable-next-line
    const response = await axios({
      method: "GET",
      url: ApiConfig.listPartner,
      headers: {
        token: window.localStorage.getItem("token"),
      },
      // params: {
      //   search: search,
      // },
    }).then(async (response) => {
      if (response.data.responseCode === 200) {
        setlistPartner(response.data.result.docs);
      } else {
        // setSearchData("");
      }
    });
  };
  const HandlelistSocials = async () => {
    //eslint-disable-next-line
    const response = await axios({
      method: "GET",
      url: ApiConfig.listSocials,
      headers: {
        token: window.localStorage.getItem("token"),
      },
      // params: {
      //   search: search,
      // },
    }).then(async (response) => {
      if (response.data.responseCode === 200) {
        setlistSocials(response.data.result.docs);
      } else {
        // setSearchData("");
      }
    });
  };

  useEffect(() => {
    HandlelistPartner();
    HandlelistSocials();
  }, [1]); //eslint-disable-line

  useEffect(() => {
    if (isLogin) {
      viewProfileHandler();
      getExchangeBalanceHandler();
      exchangeListHandler();
    } else {
      setIsLoading(false);
    }
  }, []); //eslint-disable-line

  const exchangeListHandler = async () => {
    await axios
      .get(ApiConfig.exchangeList, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          setExchangeList(res.data.result.docs);
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
  // useEffect(() => {
  //   const web = new WebSocket(socketURL);

  //   const accessToken = localStorage.getItem("token");
  //   if (accessToken) {
  //     try {
  //       web.onopen = () => {
  //         const dataToSend = {
  //           token: accessToken,
  //         };
  //         web.send(JSON.stringify(dataToSend));
  //         web.onmessage = async (event) => {
  //           if (event.data !== "[object Promise]" && event.data !== "null") {
  //             let obj = JSON.parse(event.data);
  //             if (obj.data && obj.data.length > 0) {
  //               setNotificationList(obj.data);
  //               setUnreadCount(obj.unReadCount);
  //             } else {
  //               setNotificationList([]);
  //               setUnreadCount(0);
  //             }
  //           }
  //         };
  //       };
  //       return () => {
  //         setNotificationList();
  //         setUnreadCount(0);
  //         web.close();
  //       };
  //     } catch (err) {}
  //   }
  // }, [isLogin]);

  let data = {
    added_exchange,
    // popup,
    exchangeList,
    isLoading,
    exchangeNameList,
    allExchangeTotal,
    userLoggedIn: isLogin,
    userData,
    autoTrade,
    // notificationList,
    // unreadCount,
    listPartner,
    listSocials,
    viewProfileHandler: () => viewProfileHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    exchange: (data) => {
      setAdded_exchange(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
