import React, { useContext } from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  Button,
  Drawer,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DehazeIcon from "@material-ui/icons/Dehaze";
import { AuthContext } from "src/context/Auth";
import clsx from "clsx";
import { FaSignOutAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navBar: {
    background: "#0f0f47",
    width: "100%",
    height: "auto",
    marginTop: "0",
    color: "#fafafa",
  },
  template: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  left: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  right: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  options: {
    padding: "15px",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#grey",
      borderBottom: " 2px solid #fff",
    },
    "@media (max-width:768px)": {
      display: "none",
    },
    "&:active": {
      color: "grey",
    },
  },
  options1: {
    padding: "15px",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      color: "#grey",
    },
    "@media (max-width:768px)": {
      display: "none",
    },
    "&:active": {
      color: "grey",
    },
  },
  button: {
    borderRadius: "20px",

    backgroundColor: "#ff157a",
    color: "#fff",
    width: "120px",
    border: "0px solid #ff157a",
    "&:hover": {
      backgroundColor: "transparent",

      color: "#ff157a",
    },
  },
  logo: {
    width: "180px",
    height: "35px",
    "@media (max-width: 400px)": {
      width: "100px",
      height: "20px",
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    display: "none",
    "@media(max-width:768px)": {
      display: "flex",
    },
  },
  menu: {
    width: "200px",
    textAlign: "center",
    marginTop: "40px",
    "& a": {
      color: "#fff",
    },
  },
  icon: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

function TopBar() {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);
  const auth = useContext(AuthContext);
  const [open4, setOpen4] = React.useState(false);
  const history = useHistory();
  const handleClickOpen = (id) => {
    setOpen4(true);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const logOut = () => {
    auth.userLogIn(false, null);
    history.push("/login");
    window.localStorage.removeItem("token");
  };
  return (
    <Box className={classes.navBar}>
      <Container>
        <Box className={classes.template}>
          <Box className={classes.left}>
            <Link to="/">
              <img
                src="/images/Rexchange..png"
                alt="logo"
                className={classes.logo}
              />
            </Link>
          </Box>
          <Box className={classes.right}>
            <Box className={classes.options}>
              <Typography
                variant="h6"
                to="/"
                component={Link}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                Home
              </Typography>
            </Box>
            <Box className={classes.options}>
              <Typography
                variant="h6"
                style={{ textDecoration: "none", color: "#fff" }}
                to="/about"
                component={Link}
              >
                About
              </Typography>
            </Box>
            <Box className={classes.options}>
              <Typography
                variant="h6"
                style={{ textDecoration: "none", color: "#fff" }}
                to="/price"
                component={Link}
              >
                Pricing
              </Typography>
            </Box>
            {!auth.userLoggedIn && (
              <>
                <Box className={classes.options}>
                  <Typography
                    variant="h6"
                    to="/login"
                    component={Link}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Sign In
                  </Typography>
                </Box>

                <Box className={classes.options1}>
                  <Button
                    className={classes.button}
                    to="/signup"
                    component={Link}
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Sign Up
                  </Button>
                </Box>
              </>
            )}
            {auth.userLoggedIn && (
              <Box className={classes.options1}>
                <Button
                  className={classes.button}
                  to="/dashboard"
                  component={Link}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Dashboard
                </Button>
                <Button
                  // onClick={logOut}
                  onClick={() => {
                    handleClickOpen();
                  }}
                  activeClassName={classes.active}
                  className={clsx(classes.buttonLeaf, `depth-0`)}
                >
                  <FaSignOutAlt size="20" className={classes.icon} />
                  {/* <span>{"Logout"}</span> */}
                </Button>
              </Box>
            )}
            <Box className={classes.drawer}>
              <Button onClick={() => setOpenMenu(true)}>
                <DehazeIcon style={{ color: "#fff" }} />
              </Button>
              <Drawer
                anchor={"left"}
                open={openMenu}
                onClose={() => setOpenMenu(false)}
              >
                <Box className={classes.menu}>
                  <Button
                    fullWidth
                    style={{
                      fontSize: "18px",
                      borderBottom: "1px solid grey",
                      textDecoration: "none",
                    }}
                    to="/"
                    component={Link}
                  >
                    Home
                  </Button>
                  <Button
                    fullWidth
                    style={{ fontSize: "18px", borderBottom: "1px solid grey" }}
                    to="/about"
                    component={Link}
                  >
                    About
                  </Button>
                  <Button
                    fullWidth
                    style={{ fontSize: "18px", borderBottom: "1px solid grey" }}
                    to="/price"
                    component={Link}
                  >
                    Pricing
                  </Button>
                  {auth.userLoggedIn ? (
                    <Box>
                      <Button
                        className={classes.button}
                        to="/dashboard"
                        component={Link}
                        style={{ color: "#fff", marginTop: "11px" }}
                      >
                        Dashboard
                      </Button>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingLeft: "12px",
                        }}
                      >
                        <Button
                          // onClick={logOut}
                          onClick={() => {
                            handleClickOpen();
                          }}
                          activeClassName={classes.active}
                          className={clsx(classes.buttonLeaf, `depth-0`)}
                        >
                          <FaSignOutAlt
                            size="20"
                            className={classes.icon}
                            style={{ marginTop: "11px" }}
                          />
                          {/* <span>{"Logout"}</span> */}
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      {" "}
                      <Button
                        fullWidth
                        style={{
                          fontSize: "18px",
                          borderBottom: "1px solid grey",
                          textDecoration: "none",
                        }}
                        to="/login"
                        component={Link}
                      >
                        Sign In
                      </Button>
                      <Button
                        fullWidth
                        style={{
                          fontSize: "18px",
                          borderBottom: "1px solid grey",
                        }}
                        to="/signup"
                        component={Link}
                      >
                        SignUp
                      </Button>
                    </Box>
                  )}
                </Box>
              </Drawer>
            </Box>
            <Dialog
              open={open4}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ color: "#19194b" }}>
                <Typography>Logout</Typography>
              </DialogTitle>
              <DialogContent style={{ marginTop: "-19px" }}>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to logout
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ marginTop: "-20px" }}>
                <Button onClick={handleClose4}>No</Button>
                <Button onClick={logOut} autoFocus>
                  Yes{" "}
                  {/* {blockLoader && (
              <Box>
                <CircularProgress
                  style={{ height: "20px", width: "20px", marginLeft: "5px" }}
                />
              </Box>
            )} */}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default TopBar;
