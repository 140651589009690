import React, { useContext } from "react";
import {
  Container,
  Box,
  Typography,
  makeStyles,
  Grid,
  Link,
} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { FaDiscord } from "react-icons/fa";
// import { SiDiscord } from "react-icons/si";
// import { FacebookIcon, TwitterIcon } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  footer: {
    height: "auto",
    minHeight: "20vh",
    background: "#15143e",
    color: "#fff",
    paddingTop: "50px",
    position: "relative",
    paddingBottom: "30px",
  },
  item: {
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "25px",
  },
  grid: {
    // textAlign: "left",
  },
  footerTopImg: {
    width: "100%",
    height: "20px",
    position: "absolute",
    top: "-18px",
  },
  icon: {
    paddingRight: "10px",
    cursor: "pointer",
    paddingTop: "10px",
    "&:hover": {
      color: "grey",
    },
  },
  ft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  deco: {
    textDecoration: "none",
    color: "#fff",
  },
  socialIcons: {
    // paddingLeft: "15px",
  },
}));
function Footer() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  // console.log("listSocials", auth.listSocials);

  return (
    <Box className={classes.footer}>
      <Box>
        <img src="/images/footer.png" alt="" className={classes.footerTopImg} />
      </Box>
      <Container className={classes.ft}>
        <Grid container spacing={2} className={classes.grid}>
          <Grid item xl={12} sm={3} md={3} lg={3}>
            <Box>
              <img
                src="/images/Rexchange..png"
                alt=""
                className={classes.footerTopIm}
              />
              <Typography variant="h3" style={{ paddingLeft: "20px" }}>
                {/* Follow Us */}
              </Typography>
              <Box className={classes.socialIcons}>
                {auth.listSocials &&
                  auth.listSocials.map((socialLink, i) => {
                    return (
                      <Link
                        target="_blanck"
                        href={socialLink.url}
                        className={classes.deco}
                      >
                        {socialLink.socialMediaName === "instagram" && (
                          <InstagramIcon className={classes.icon} />
                        )}
                        {socialLink.socialMediaName === "facebook" && (
                          <FacebookIcon className={classes.icon} />
                        )}
                        {socialLink.socialMediaName === "twitter" && (
                          <TwitterIcon className={classes.icon} />
                        )}
                        {socialLink.socialMediaName === "linkedin" && (
                          <LinkedInIcon className={classes.icon} />
                        )}
                        {/* {socialLink.socialMediaName === "discord" && (
                          <SiDiscord className={classes.icon} />
                        )} */}
                      </Link>
                    );
                  })}

                {/* <Link
                  target="_blanck"
                  href="https://twitter.com/"
                  className={classes.deco}
                >
                  <TwitterIcon className={classes.icon} />
                </Link>
                <Link
                  target="_blanck"
                  href="https://www.instagram.com/"
                  className={classes.deco}
                >
                  <InstagramIcon className={classes.icon} />
                </Link>
                <Link
                  target="_blanck"
                  href="https://www.linkedin.com/"
                  className={classes.deco}
                >
                  <LinkedInIcon className={classes.icon} />
                </Link> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} sm={3} md={3} lg={3}>
            <Typography variant="h3" style={{ paddingLeft: "20px" }}>
              Company & Team
            </Typography>
            <Box className={classes.item}>
              <ul>
                <RouterLink to="/about" className={classes.deco}>
                  <li>About</li>
                </RouterLink>
                <RouterLink to="/news" className={classes.deco}>
                  <li>News & Blog</li>
                </RouterLink>
                <RouterLink to="/press" className={classes.deco}>
                  <li>Press about us</li>
                </RouterLink>
                <RouterLink to="/ourPartners" className={classes.deco}>
                  <li>Our partners</li>
                </RouterLink>
              </ul>
            </Box>
          </Grid>
          <Grid item xl={12} sm={3} md={3} lg={3}>
            <Box>
              <Typography variant="h3" style={{ paddingLeft: "20px" }}>
                Help & Support
              </Typography>
              <Box className={classes.item}>
                <ul>
                  <RouterLink to="/contact-us" className={classes.deco}>
                    <li>Support</li>
                  </RouterLink>
                  <RouterLink to="/faq" className={classes.deco}>
                    <li>FAQ</li>
                  </RouterLink>
                  <RouterLink
                    to="/supported-exchanges"
                    className={classes.deco}
                  >
                    <li>Supported Exchanges</li>
                  </RouterLink>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={12} sm={3} md={3} lg={3}>
            <Typography variant="h3" style={{ paddingLeft: "20px" }}>
              Tools
            </Typography>
            <Box className={classes.item}>
              <ul>
                <RouterLink to="/exchange-widget" className={classes.deco}>
                  <li>Exchange widget</li>
                </RouterLink>
                {/* <RouterLink to="/" className={classes.deco}>
                  <li>Payment button</li>
                </RouterLink> */}
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
