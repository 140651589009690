import React from "react";
import PropTypes from "prop-types";
import TopBar from "./TopBar";
import Footer from "./Footer";

const LoginLayout = ({ children }) => {
  return (
    <>
      <TopBar />
      <div>{children}</div>
      <Footer />
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
