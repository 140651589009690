import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { socketURL } from "src/config/APICongig";

export const UserContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function UserAuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());

  const [added_exchange, setAdded_exchange] = useState([]);

  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    const web = new WebSocket(socketURL);

    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      try {
        web.onopen = () => {
          const dataToSend = {
            token: accessToken,
          };
          web.send(JSON.stringify(dataToSend));
          web.onmessage = async (event) => {
            if (event.data !== "[object Promise]" && event.data !== "null") {
              let obj = JSON.parse(event.data);
              if (obj.data && obj.data.length > 0) {
                setNotificationList(obj.data);
                setUnreadCount(obj.unReadCount);
              } else {
                setNotificationList([]);
                setUnreadCount(0);
              }
            }
          };
        };
        return () => {
          setNotificationList();
          setUnreadCount(0);
          web.close();
        };
      } catch (err) {}
    }
  }, [isLogin]);

  let data = {
    added_exchange,

    userLoggedIn: isLogin,

    notificationList,
    unreadCount,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    exchange: (data) => {
      setAdded_exchange(data);
    },
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
