// const url = "http://172.16.1.176:1852";
const url = "https://node-arbitrage.mobiloitte.com";
export const socketURL = "wss://node-arbitrage.mobiloitte.com";

// const url = "http://172.16.1.176:1852";
// export const socketURL = "ws://172.16.1.176:1852";

const user = `${url}/api/v1/user`;
const statics = `${url}/api/v1/static`;
const faq = `${url}/api/v1/faq`;
const direct = `${url}/api/v1/direct`;
const triangular = `${url}/api/v1/triangular`;
const intra = `${url}/api/v1/intra`;
const interloop = `${url}/api/v1/interloop`;
const wallet = `${url}/api/v1/wallet`;

const auth = `${url}/auth`;

const ApiConfig = {
  auth: `${auth}`,
  googleTwoFAAuth: `${auth}/verify-google`,
  viewProfile: `${user}/viewProfile`,
  signUp: `${user}/signUp`,
  twoFALogin: `${user}/twoFALogin`,
  verifyUser: `${user}/verify-user`,
  myaccount: `${user}/login`,
  otpVerify: `${user}/otpVerify`,
  resendOtp: `${user}/resendOtp`,
  exchangeList: `${user}/exchangeList`,
  connectExchange: `${user}/connectExchange`,
  connectedExchangesList: `${user}/connectedExchangesList`,
  getExchangeBalance: `${wallet}/getExchangeBalance`,
  autoTradeCoins: `${wallet}/autoTradeCoins`,
  generate2FAQR: `${user}/generate2FAQR`,
  createRuleEvent: `${user}/createRuleEvent`,
  getDirectExchange: `${user}/getDirectExchange`,
  getcoin: `${user}/getcoin`,
  getAssets: `${user}/getAssets`,
  getWallet: `${user}/getWallet`,
  depthTriangularArbitrage: `${user}/depthTriangularArbitrage`,
  forgotPassword: `${user}/forgotPassword`,
  getInterLoopExchange: `${user}/getInterLoopExchange`,
  getIntraArbitrage: `${user}/getIntraArbitrage`,
  resetPassword: `${user}/resetPassword`,

  applyRemove2FA: `${user}/applyRemove2FA`,
  removeExchange: `${user}/removeExchange`,
  buy_sell_DepthTriangularArbitrage: `${user}/buy_sell_DepthTriangularArbitrage`,
  listPlacedDepthTriangularArbitrage: `${user}/listPlacedDepthTriangularArbitrage`,
  viewPlacedDepthTriangularArbitrage: `${user}/viewPlacedDepthTriangularArbitrage`,
  buySell_directExchange: `${user}/buySell_directExchange`,
  list_Directarbitrage: `${user}/list_Directarbitrage`,
  view_Directarbitrage: `${user}/view_Directarbitrage`,

  getpath: `${user}/getpath`,
  // exchangeList: `${user}/exchangeList`,
  getUpdateSubscription: `${user}/getUpdateSubscription`,

  listRuleEvent: `${user}/listRuleEvent`,
  viewRuleEvent: `${user}/viewRuleEvent`,
  activeBlockRuleEvent: `${user}/activeBlockRuleEvent`,
  totalArbitrages: `${user}/totalArbitrages`,
  stats: `${user}/stats`,
  totalProfit: `${user}/totalProfit`,
  listNotification: `${user}/listNotification`,
  readNotification: `${user}/readNotification`, //api/v1/faq/fAQList
  buySell_IntraArbitrage: `${user}/buySell_IntraArbitrage`,
  view_IntraArbitrage: `${user}/view_IntraArbitrage`,
  list_IntraArbitrage: `${user}/list_IntraArbitrage`,
  buySell_interLoopExchange: `${user}/buySell_interLoopExchange`,
  view_interLoopExchange: `${user}/view_Looparbitrage`,
  list_Looparbitrage: `${user}/list_Looparbitrage`,
  onOffNotification: `${user}/onOffNotification`,
  baseCurrency: `${user}/baseCurrency`,
  listSubscription: `${user}/listSubscription`,
  buySubscription: `${user}/buySubscription`,
  getCurrencyPairList: `${user}/getCurrencyPairList`,
  getCurrencyPairData: `${user}/getCurrencyPairData`,
  listPlan: `${user}/listPlan`,

  fAQList: `${faq}/fAQList`,
  staticContentList: `${statics}/staticContentList`,

  createDirectOrder: `${user}/createDirectOrder`,
  serverIPAddress: `${statics}/serverIPAddress`,

  // direct
  getProfitPathDa: `${direct}/getProfitPaths`,
  listPlacedTradeDa: `${direct}/listPlacedTrade`,
  tradeProfitPathDa: `${direct}/tradeProfitPaths`,
  viewPlacedTradeDa: `${direct}/viewPlacedTrade`,
  activeBlockPlacedTradeDa: `${direct}/activeBlockPlacedTrade`,
  deletePlacedTradeDa: `${direct}/deletePlacedTrade`,
  autoTradeOnOffDa: `${direct}/autoTradeOnOff`,
  cancelledOrderDa: `${direct}/cancelledOrder/`,

  // traiangular
  getProfitPathTa: `${triangular}/getProfitPaths`,
  listPlacedTradeTa: `${triangular}/listPlacedTrade`,
  tradeProfitPathTa: `${triangular}/tradeProfitPaths`,
  viewPlacedTradeTa: `${triangular}/viewPlacedTrade`,
  activeBlockPlacedTradeTA: `${triangular}/activeBlockPlacedTrade`,
  deletePlacedTradeTA: `${triangular}/deletePlacedTrade`,
  autoTradeOnOffTa: `${triangular}/autoTradeOnOff`,
  cancelledOrderTa: `${triangular}/cancelledOrder/`,

  // intra
  getProfitPathIa: `${intra}/getProfitPaths`,
  listPlacedTradeIa: `${intra}/listPlacedTrade`,
  tradeProfitPathIa: `${intra}/tradeProfitPaths`,
  viewPlacedTradeIa: `${intra}/viewPlacedTrade`,
  activeBlockPlacedTradeIa: `${intra}/activeBlockPlacedTrade`,
  deletePlacedTradeIa: `${intra}/deletePlacedTrade`,
  autoTradeOnOffIa: `${intra}/autoTradeOnOff`,
  cancelledOrderIa: `${intra}/cancelledOrder/`,

  // interloop
  getProfitPathIl: `${interloop}/getProfitPaths`,
  listPlacedTradeIl: `${interloop}/listPlacedTrade`,
  tradeProfitPathIl: `${interloop}/tradeProfitPaths`,
  viewPlacedTradeIl: `${interloop}/viewPlacedTrade`, //deletePlacedTradeTA
  activeBlockPlacedTradeIl: `${interloop}/activeBlockPlacedTrade`,
  deletePlacedTradeIl: `${interloop}/deletePlacedTrade`,
  autoTradeOnOffIl: `${interloop}/autoTradeOnOff`,
  cancelledOrderIl: `${interloop}/cancelledOrder/`,

  // /api/v1/user/listFAQ
  listPartner: `${user}/listPartner`,
  listSocials: `${user}/listSocials`,
  fAQList: `${user}/listFAQ`,
  submitUserQuery: `${user}/submitUserQuery`,
};

export default ApiConfig;
